<template>
    <div class="section">
        <div class="block-playlist">
            <div class="block-playlist__carousel-wrap" v-if="chapters">
                <carousel :perPage="perView" :perPageCustom="perPageCustom" :paginationEnabled="false"
                          :navigationEnabled="true"
                          :navigationPrevLabel="arrowLeft"
                          :navigationNextLabel="arrowRight">
                    <slide v-for="(chapter, key) in chapters" :key="'id-'+key">
                        <transition name="slide-chapter">
                            <div @click="play(chapter)" class="playlist-chapter"
                                 v-show="runningAnimation">
                                <div class="playlist-chapter__media">
                                    <img v-if="chapter.thumbnail" :src="chapter.thumbnail.sizes.miniature"/>
                                </div>
                                <h3 v-if="chapter.title" class="playlist-chapter__title">
                                    {{chapter.title.rendered?chapter.title.rendered:chapter.title}}</h3>
                                <p v-if="chapter.description" class="playlist-chapter__info" v-html="chapter.description"></p>
                            </div>
                        </transition>

                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>
<script>
    import carouselMixin from '../mixins/carouselMixin'
    import animationMixin from '../mixins/animationMixin'
    import {EventBus} from "../main";

    export default {
        props: ['chapters', 'title'],
        mixins: [animationMixin, carouselMixin],
        methods: {
            play(chapter) {
                this.$emit('click', {
                    seek: chapter.start_at_second
                })
                // EventBus.$emit('selectVideo', {
                //     action: 'chapter',
                //     time: chapter.start_at_second
                // })
                // EventBus.$emit('closeOverlay')
            }
        }
    }
</script>

