<template>
    <video-playlist v-if="video && video.video_chapters"
                    key="'episodes'" @click="clickHandler"
                    :chapters="video.video_chapters"></video-playlist>
</template>

<script>
    import store from "../store";
    import VideoPlaylist from "../components/videoPlaylist";

    export default {
        name: "Episodes",
        components: {VideoPlaylist},
        props: {active: {type: String}},
        computed: {
            video() {
                return store.video
            }
        },

        methods: {
            clickHandler(payload) {
                this.$router.push({
                    name: 'WatchMain',
                    params: {
                        chapter: payload.seek,
                        seek: payload.seek,
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
